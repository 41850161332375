import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { MyContext } from '../context/MyContext';

import hyappLogo from '../resource/img/hyapp-securite-alimentaire-blanc.svg';

function TopBar() {

  const { loggedUser } = useContext(MyContext);
  const location = useLocation();
  return (

    <nav className="navbar is-black is-fixed-top" role="navigation">
      <div className="navbar-brand" style={{justifyContent: 'space-between', width: '100%'}}>
        <a className="navbar-item" title="Hÿapp" href="/">
          <img src={hyappLogo} alt="Logo HyAPP" width="112" height="28" />
        </a>
        {
          loggedUser ? null : 
         (location.pathname === "/login" ? <Link to="/login" className="navbar-item">Espace client</Link> : <Link to="/login" className="navbar-item">Se connecter</Link>)
        }
      </div>
    </nav>

  );
}

export default TopBar;