import React, { useEffect, useContext, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { MyContext } from '../context/MyContext';

import Select from 'react-select';

import collaborateursLogo from '../resource/img/collaborateurs-fondnoir.svg';
import etablissementsLogo from '../resource/img/etablissement-fondnoir.svg';
import administrateursLogo from '../resource/img/administrateurs-fondnoir.svg';
import abonnementLogo from '../resource/img/abonnement-fondnoir.svg';
import fournisseursLogo from '../resource/img/fournisseurs-fondnoir.svg';
import logoutLogo from '../resource/img/logout-fondnoir.svg';
import receptionLogo from '../resource/img/reception-fondnoir.svg';
import huileLogo from '../resource/img/huile-fondnoir.svg';
import temperatureLogo from '../resource/img/temperature-fondnoir.svg';
import tracabiliteLogo from '../resource/img/tracabilite-fondnoir.svg';
import refroidissementLogo from '../resource/img/refroidissement-fondnoir.svg';
import remiseTemperatureLogo from '../resource/img/remise-t-fondnoir.svg';
import nettoyageLogo from '../resource/img/nettoyage-fondnoir.svg';
import nonConformiteLogo from '../resource/img/non-conformite-fondnoir.svg';
import mixeLogo from '../resource/img/mixe-fondnoir.svg';
import exportLogo from '../resource/img/export-fondnoir.svg';
import fermetureLogo from '../resource/img/fermeture-fondnoir.svg';
import serviceLogo from '../resource/img/service-fondnoir.svg';
import sondeLogo from '../resource/img/sonde-fondnoir.svg';


const etablissementStyle = {
  control: styles => ({ ...styles, backgroundColor: 'black', color: 'white' }),
  option: styles => ({ ...styles, backgroundColor: 'black', color: 'white' }),

  placeholder: styles => ({ ...styles, backgroundColor: 'black', color: 'lightGray' }),
  singleValue: styles => ({ ...styles, backgroundColor: 'black', color: 'white' }),

  multiValue: styles => ({ ...styles, backgroundColor: 'black', color: 'white' }),
  menuList: styles => ({ ...styles, backgroundColor: 'darkGray', borderColor: 'white' }),
  noOptionsMessage: styles => ({ ...styles, backgroundColor: 'black', borderColor: 'white' }),

}


function Menu() {

  const { loggedUser, selectedEtablissement, etablissements, setSelectedEtablissement } = useContext(MyContext);

  const [huileExpanded, setHuileExpanded] = useState(false);
  const [enceinteExpanded, setEnceinteExpanded] = useState(false);
  const [refroidissementExpanded, setRefroidissementExpanded] = useState(false);
  const [remiseExpanded, setRemiseExpanded] = useState(false);
  const [nettoyageExpanded, setNettoyageExpanded] = useState(false);
  const [mixeExpanded, setMixeExpanded] = useState(false);
  const [gestionExpanded, setGestionExpanded] = useState(false);
  const [receptionExpanded, setReceptionExpanded] = useState(false);

  const toggleGestion = () => {
    setEnceinteExpanded(false);
    setRefroidissementExpanded(false);
    setRemiseExpanded(false);
    setNettoyageExpanded(false);
    setMixeExpanded(false);
    setHuileExpanded(false);
    setGestionExpanded(prevGestionExpanded => !prevGestionExpanded);
    setReceptionExpanded(false);
  }

  const toggleReception = () => {
    setEnceinteExpanded(false);
    setRefroidissementExpanded(false);
    setRemiseExpanded(false);
    setNettoyageExpanded(false);
    setMixeExpanded(false);
    setHuileExpanded(false);
    setGestionExpanded(false);
    setReceptionExpanded(prevReceptionExpanded => !prevReceptionExpanded);
  }

  const toggleHuile = () => {
    setEnceinteExpanded(false);
    setRefroidissementExpanded(false);
    setRemiseExpanded(false);
    setNettoyageExpanded(false);
    setMixeExpanded(false);
    setHuileExpanded(prevHuileExpanded => !prevHuileExpanded);
    setGestionExpanded(false);
    setReceptionExpanded(false);
  }
  const toggleEnceinte = () => {
    setHuileExpanded(false);
    setRefroidissementExpanded(false);
    setRemiseExpanded(false);
    setNettoyageExpanded(false);
    setMixeExpanded(false);
    setEnceinteExpanded(prevEnceinteExpanded => !prevEnceinteExpanded);
    setGestionExpanded(false);
    setReceptionExpanded(false);
  }
  const toggleRefroidissement = () => {
    setHuileExpanded(false);
    setEnceinteExpanded(false);
    setRemiseExpanded(false);
    setNettoyageExpanded(false);
    setMixeExpanded(false);
    setRefroidissementExpanded(prevRefroidissementExpanded => !prevRefroidissementExpanded);
    setGestionExpanded(false);
    setReceptionExpanded(false);
  }
  const toggleRemise = () => {
    setHuileExpanded(false);
    setEnceinteExpanded(false);
    setRefroidissementExpanded(false);
    setNettoyageExpanded(false);
    setMixeExpanded(false);
    setRemiseExpanded(prevRemiseExpanded => !prevRemiseExpanded);
    setGestionExpanded(false);
    setReceptionExpanded(false);
  }
  const toggleNettoyage = () => {
    setHuileExpanded(false);
    setEnceinteExpanded(false);
    setRefroidissementExpanded(false);
    setRemiseExpanded(false);
    setMixeExpanded(false);
    setNettoyageExpanded(prevNettoyageExpanded => !prevNettoyageExpanded);
    setGestionExpanded(false);
    setReceptionExpanded(false);
  }
  const toggleMixe = () => {
    setHuileExpanded(false);
    setEnceinteExpanded(false);
    setRefroidissementExpanded(false);
    setRemiseExpanded(false);
    setNettoyageExpanded(false);
    setMixeExpanded(prevMixeExpanded => !prevMixeExpanded);
    setGestionExpanded(false);
    setReceptionExpanded(false);
  }

  useEffect(() => {
  }, []);

  const navigate = useNavigate();

  const selectEtablissement = (elt) => {
    setSelectedEtablissement(elt);
    navigate("/etablissement/" + elt.id + "/view");
    navigate("/etablissement/" + elt.id + "/view");
  }

  const commonMenu = () => {
    const imgSize = {
      width: "35px",
      marginRight: "0.5rem"
    }
    return <>
      <p class="menu-label">
        Mon Hyapp
      </p>
      <Select noOptionsMessage={() => "Aucune correspondance"} options={etablissements} value={selectedEtablissement} onChange={elt => { selectEtablissement(elt) }} placeholder={"Choisir un établissement..."} styles={etablissementStyle} />
      {
        selectedEtablissement ?
          (<>
            <ul className="menu-list">
              {
                selectedEtablissement.modules && selectedEtablissement.modules.includes("reception") &&
                <>
                  <li><a onClick={toggleReception} style={{ verticalAlign: "middle" }}><img src={receptionLogo} style={imgSize} className="vmiddle" alt="" />Contrôles à réception
                    {receptionExpanded ? <i className="fa fa-caret-down" style={{ float: 'right', paddingTop: 4 }} /> : <i className="fa fa-caret-left" style={{ float: 'right', paddingTop: 4 }} />}
                  </a>
                    <ul className={receptionExpanded ? "menu-list" : "menu-list is-hidden"}>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/fournisseur"} className={({ isActive }) => isActive ? "is-active" : ""}><img src={fournisseursLogo} style={imgSize} className="vmiddle" alt="" />Fournisseurs</NavLink></li>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/reception"} className={({ isActive }) => isActive ? "is-active" : ""}><img src={receptionLogo} style={imgSize} className="vmiddle" alt="" />Réceptions réalisées</NavLink></li>
                    </ul>
                  </li>
                </>
              }
              {
                selectedEtablissement.modules && selectedEtablissement.modules.includes("releve_temperature_stockage") &&
                <>
                  <li><a onClick={toggleEnceinte} style={{ verticalAlign: "middle" }}><img src={temperatureLogo} style={imgSize} className="vmiddle" alt="" />T°C enceintes
                    {enceinteExpanded ? <i className="fa fa-caret-down" style={{ float: 'right', paddingTop: 4 }} /> : <i className="fa fa-caret-left" style={{ float: 'right', paddingTop: 4 }} />}
                  </a>
                    <ul className={enceinteExpanded ? "menu-list" : "menu-list is-hidden"}>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/enceinte_stockage"} className={({ isActive }) => isActive ? "is-active" : ""}>Enceintes de stockage</NavLink></li>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/releve_temperature_stockage"} className={({ isActive }) => isActive ? "is-active" : ""}>Relevés de température</NavLink></li>
                    </ul>
                  </li>
                </>
              }
              {selectedEtablissement.modules && selectedEtablissement.modules.includes("tracabilite") && <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/tracabilite"} className={({ isActive }) => isActive ? "is-active" : ""}><img src={tracabiliteLogo} style={imgSize} className="vmiddle" alt="" />Traçabilité</NavLink></li>}
              {
                selectedEtablissement.modules && selectedEtablissement.modules.includes("nettoyage") &&
                <>
                  <li><a onClick={toggleNettoyage} style={{ verticalAlign: "middle" }}><img src={nettoyageLogo} style={imgSize} className="vmiddle" alt="" />Nettoyage
                    {nettoyageExpanded ? <i className="fa fa-caret-down" style={{ float: 'right', paddingTop: 4 }} /> : <i className="fa fa-caret-left" style={{ float: 'right', paddingTop: 4 }} />}
                  </a>
                    <ul className={nettoyageExpanded ? "menu-list" : "menu-list is-hidden"}>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/zone"} className={({ isActive }) => isActive ? "is-active" : ""}>Plan de nettoyage</NavLink></li>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/nettoyage"} className={({ isActive }) => isActive ? "is-active" : ""}>Nettoyages réalisés</NavLink></li>
                    </ul>
                  </li>
                </>
              }
              {
                selectedEtablissement.modules && selectedEtablissement.modules.includes("releve_refroidissement") &&
                <>
                  <li><a onClick={toggleRefroidissement} style={{ verticalAlign: "middle" }}><img src={refroidissementLogo} style={imgSize} className="vmiddle" alt="" />Refroidissement
                    {refroidissementExpanded ? <i className="fa fa-caret-down" style={{ float: 'right', paddingTop: 4 }} /> : <i className="fa fa-caret-left" style={{ float: 'right', paddingTop: 4 }} />}
                  </a>
                    <ul className={refroidissementExpanded ? "menu-list" : "menu-list is-hidden"}>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/cellule_refroidissement"} className={({ isActive }) => isActive ? "is-active" : ""}>Cellules de refroidissement</NavLink></li>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/releve_refroidissement"} className={({ isActive }) => isActive ? "is-active" : ""}>Refroidissements réalisés</NavLink></li>
                    </ul>
                  </li>
                </>
              }
              {
                selectedEtablissement.modules && selectedEtablissement.modules.includes("remise_temp") &&
                <>
                  <li><a onClick={toggleRemise} style={{ verticalAlign: "middle" }}><img src={remiseTemperatureLogo} style={imgSize} className="vmiddle" alt="" />Remise T°C
                    {remiseExpanded ? <i className="fa fa-caret-down" style={{ float: 'right', paddingTop: 4 }} /> : <i className="fa fa-caret-left" style={{ float: 'right', paddingTop: 4 }} />}
                  </a>
                    <ul className={remiseExpanded ? "menu-list" : "menu-list is-hidden"}>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/enceinte_remise"} className={({ isActive }) => isActive ? "is-active" : ""}>Enceintes de remise T°C</NavLink></li>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/remise_temp"} className={({ isActive }) => isActive ? "is-active" : ""}>Remises en T°C réalisées</NavLink></li>
                    </ul>
                  </li>
                </>
              }
              {
                selectedEtablissement.modules && selectedEtablissement.modules.includes("releve_huile") &&
                <>
                  <li><a onClick={toggleHuile} style={{ verticalAlign: "middle" }}><img src={huileLogo} style={imgSize} className="vmiddle" alt="" />Huiles
                    {huileExpanded ? <i className="fa fa-caret-down" style={{ float: 'right', paddingTop: 4 }} /> : <i className="fa fa-caret-left" style={{ float: 'right', paddingTop: 4 }} />}
                  </a>
                    <ul className={huileExpanded ? "menu-list" : "menu-list is-hidden"}>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/friteuse"} className={({ isActive }) => isActive ? "is-active" : ""}>Bains friteuses</NavLink></li>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/releve_huile"} className={({ isActive }) => isActive ? "is-active" : ""}>Contrôle des huiles</NavLink></li>
                    </ul>
                  </li>
                </>
              }

              {selectedEtablissement.modules && selectedEtablissement.modules.includes("mixe") &&
                <>
                  <li><a onClick={toggleMixe} style={{ verticalAlign: "middle" }}><img src={mixeLogo} style={imgSize} className="vmiddle" alt="" />Mixés
                    {nettoyageExpanded ? <i className="fa fa-caret-down" style={{ float: 'right', paddingTop: 4 }} /> : <i className="fa fa-caret-left" style={{ float: 'right', paddingTop: 4 }} />}
                  </a>
                    <ul className={mixeExpanded ? "menu-list" : "menu-list is-hidden"}>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/appareil_mixage"} className={({ isActive }) => isActive ? "is-active" : ""}>Appareils de mixage</NavLink></li>
                      <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/mixe"} className={({ isActive }) => isActive ? "is-active" : ""}>Mixés réalisés</NavLink></li>
                    </ul>
                  </li>
                </>

              }



              {selectedEtablissement.modules && selectedEtablissement.modules.includes("temp_service") && <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/temp_service"} className={({ isActive }) => isActive ? "is-active" : ""}><img src={serviceLogo} style={imgSize} className="vmiddle" alt="" />T°C service</NavLink></li>}




              <li><a onClick={toggleGestion} style={{ verticalAlign: "middle" }}><img src={exportLogo} style={imgSize} className="vmiddle" alt="" />Gestion du compte
                {gestionExpanded ? <i className="fa fa-caret-down" style={{ float: 'right', paddingTop: 4 }} /> : <i className="fa fa-caret-left" style={{ float: 'right', paddingTop: 4 }} />}
              </a>
                <ul className={gestionExpanded ? "menu-list" : "menu-list is-hidden"}>


                  <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/non_conformite"} className={({ isActive }) => isActive ? "is-active" : ""}><img src={nonConformiteLogo} style={imgSize} className="vmiddle" alt="" />Non-conformité</NavLink></li>
                  <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/fermeture_etablissement"} className={({ isActive }) => isActive ? "is-active" : ""}><img src={fermetureLogo} style={imgSize} className="vmiddle" alt="" />Fermetures de l'établissement</NavLink></li>
                  <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/export"} className={({ isActive }) => isActive ? "is-active" : ""}><img src={exportLogo} style={imgSize} className="vmiddle" alt="" />Exporter des données</NavLink></li>
                  <li><NavLink end to={"/etablissement/" + selectedEtablissement.id + "/note"} className={({ isActive }) => isActive ? "is-active" : ""}><img src={nonConformiteLogo} style={imgSize} className="vmiddle" alt="" />Notes</NavLink></li>
                </ul>
              </li>
            </ul>
          </>)
          : (<></>)
      }
    </>
  }

  //si besoin de remettre les notes
  //<li><NavLink end to="/note" className={({ isActive }) => isActive ? "is-active" : ""}><img src={nonConformiteLogo} style={imgSize} className="vmiddle" alt="" />Notes</NavLink></li>

  const adminMenu = () => {

    const imgSize = {
      width: "35px",
      marginRight: "0.5rem"
    }

    return <aside className="menu has-text-white " >
      <p className="menu-label">
        Général
      </p>
      <ul className="menu-list">
        <li><NavLink end to="/administrateur" className={({ isActive }) => isActive ? "is-active" : ""}><img src={administrateursLogo} style={imgSize} className="vmiddle" alt="" />Administrateurs</NavLink></li>
        <li><NavLink end to="/abonnement" className={({ isActive }) => isActive ? "is-active" : ""}><img src={abonnementLogo} style={imgSize} className="vmiddle" alt="" />Abonnements</NavLink></li>
        <li><NavLink end to="/etablissement" className={({ isActive }) => isActive ? "is-active" : ""}><img src={etablissementsLogo} style={imgSize} className="vmiddle" alt="" />Etablissements</NavLink></li>
        <li><NavLink end to="/utilisateur" className={({ isActive }) => isActive ? "is-active" : ""}><img src={collaborateursLogo} style={imgSize} className="vmiddle" alt="" />Utilisateurs</NavLink></li>
        <li><NavLink end to="/sonde" className={({ isActive }) => isActive ? "is-active" : ""}><img src={sondeLogo} style={imgSize} className="vmiddle" alt="" />Sondes</NavLink></li>
        <li><NavLink end to="/logout" className={({ isActive }) => isActive ? "is-active" : ""}><img src={logoutLogo} style={imgSize} className="vmiddle" alt="" />Se déconnecter</NavLink> </li>


      </ul>
      {
        commonMenu()
      }
    </aside>
  };

  const gerantMenu = () => {

    const imgSize = {
      width: "35px",
      marginRight: "0.5rem"
    }

    return <aside className="menu has-text-white is-fullheight-with-navbar" >
      <p className="menu-label">
        Général
      </p>
      <ul className="menu-list">
        <li><NavLink end to="/etablissement" className={({ isActive }) => isActive ? "is-active" : ""}><img src={etablissementsLogo} style={imgSize} className="vmiddle" alt="" />Etablissements</NavLink></li>
        <li><NavLink end to="/utilisateur" className={({ isActive }) => isActive ? "is-active" : ""}><img src={collaborateursLogo} style={imgSize} className="vmiddle" alt="" />Utilisateurs</NavLink></li>
        <li><NavLink end to="/logout" className={({ isActive }) => isActive ? "is-active" : ""}><img src={logoutLogo} style={imgSize} className="vmiddle" alt="" />Se déconnecter</NavLink> </li>
      </ul>

      {
        commonMenu()
      }
    </aside>
  };

  return (
    <div className="column is-one-fifth has-background-black has-text-white is-fullheight is-narrow">
      {
        loggedUser ?
          (
            loggedUser.role_role_id === 0 || loggedUser.role_role_id === 10
              ? adminMenu()
              : gerantMenu()
          )
          :
          (<></>)
      }
    </div>
  );
}


export default Menu;