import React, { useEffect } from 'react';
import logoLabhya from '../resource/img/logo-labhya.png';

function Footer() {

  useEffect(() => {
  }, []);

  return (
    <>
      <footer className="footer mc-auto" style={{backgroundColor: '#FABD00'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flex: 1, width: '33%'}}>
            &nbsp;
          </div>
          <div style={{flex: 1, width: '33%', textAlign:'center'}}>
            <p>Besoin d'aide : <a href="mailto:assistance@hyapp.fr">assistance@hyapp.fr</a><br />
              Conception et d&eacute;veloppement <a href="https://www.europe-internet.net" target="_blank" rel="noopener">WSI Europe Internet</a>
            </p>
          </div>
          <div style={{flex: 1, width: '33%', textAlign: 'right'}}>
            <a href="https://www.labhya.fr/"><img src={logoLabhya} alt="Logo labhya" style={{height: '50px', }}/></a>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;