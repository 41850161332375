import React, { useEffect, useState, useContext } from 'react';
import { MyContext } from '../context/MyContext';
import { getCsv } from '../Helper/Network';

import { HyappDatePicker, HyappSelect } from '../Helper/Form';
import { toast } from 'react-toastify';

import { Button } from 'react-bulma-components';

const convertDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1);
    const day = (date.getDate());
    return year.toString() + "-" + month.toString().padStart(2, '0') + "-" + day.toString().padStart(2, '0');
}

function Export() {
    const { selectedEtablissement } = useContext(MyContext);
    var df = new Date();
    df.setHours(0, 0, 0, 0);
    df.setMonth(df.getMonth() - 1)
    var dt = new Date();
    dt.setHours(0, 0, 0, 0);

    const [modules, setModules] = useState([]);
    const [dateFrom, setDateFrom] = useState(convertDate(df));
    const [dateTo, setDateTo] = useState(convertDate(dt));
    const [dataType, setDataType] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true);

    const exportData = async () => {
        try {
            const response = await getCsv("/etablissement/" + selectedEtablissement.id + "/export", {
                from: dateFrom,
                to: dateTo,
                dataType: dataType.tech
            }, dataType.tech + "-" + dateFrom + "-" + dateTo + ".csv");
        }
        catch (error) { //in case of error display toast
            toast.error(error.statusText);
        }
    };

    useEffect(() => {
        if (firstLoad) {
            const multiSelect = [];
            if (selectedEtablissement.modules != null && selectedEtablissement.modules.includes("reception")) { multiSelect.push({value: 1, label:"Réceptions", tech: "reception"}) }
            if (selectedEtablissement.modules != null && selectedEtablissement.modules.includes("releve_temperature_stockage")) { multiSelect.push({value: 2, label:"Relevés des températures de stockage", tech: "releve_temperature_stockage"}) }
            if (selectedEtablissement.modules != null && selectedEtablissement.modules.includes("tracabilite")) { multiSelect.push({value: 3, label:"Tracabilités", tech: "tracabilite"}) }
            if (selectedEtablissement.modules != null && selectedEtablissement.modules.includes("releve_refroidissement")) { multiSelect.push({value: 4, label:"Refroidissements", tech: "releve_refroidissement"}) }
            if (selectedEtablissement.modules != null && selectedEtablissement.modules.includes("remise_temp")) { multiSelect.push({value: 5, label:"Remises T°C", tech: "remise_temp"}) }
            if (selectedEtablissement.modules != null && selectedEtablissement.modules.includes("releve_huile")) { multiSelect.push({value: 6, label:"Huiles", tech: "releve_huile"}) }
            if (selectedEtablissement.modules != null && selectedEtablissement.modules.includes("nettoyage")) { multiSelect.push({value: 7, label:"Nettoyages", tech: "nettoyage"}) }
            if (selectedEtablissement.modules != null && selectedEtablissement.modules.includes("temp_service")) { multiSelect.push({value: 8, label:"Températures de service", tech: "temp_service"}) }
            if (selectedEtablissement.modules != null && selectedEtablissement.modules.includes("mixe")) { multiSelect.push({value: 9, label:"Mixés", tech: "mixe"}) }

            setModules(multiSelect);
            setFirstLoad(false);
        }
        //do Nothing
    }, []);

    return (
        <>
            <div className="columns">
                <div className="column">
                    <h1 className="hyappTitle">Exporter les données</h1>
                </div>
            </div>
            <div className="columns is-multiline is-vcentered">
                <div className="column is-one-fifth label-right">
                    <label className="label">Du :</label>
                </div>
                <div className="column is-four-fifths edit-value">
                    <HyappDatePicker value={dateFrom} callback={setDateFrom} />
                </div>
                <div className="column is-one-fifth label-right">
                    <label className="label">Au :</label>
                </div>
                <div className="column is-four-fifths edit-value">
                    <HyappDatePicker value={dateTo} callback={setDateTo} />
                </div>
                
                <HyappSelect label={"Type de données"} data={modules} callback={setDataType} value={dataType}/>
                
            </div>
            <Button className="button is-success" onClick={exportData}>Exporter les données</Button>
        </>
    );
}

export default Export;