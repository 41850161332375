import axios from 'axios';

import {toast} from 'react-toastify';

const axiosError = (error) => {
  const errorMessage = "Une erreur est survenue, veuillez réessayer dans quelques instants ou contacter votre support technique";

  if (error.response) {

    const status = error.response.status;

    var message = undefined;

    if (error.response.data ) {
      if (error.response.data.message ) {
        message = error.response.data.message;
      }
    }

    if (!message) {
      if (status === 401) {
        message = "Vous n'êtes pas autorisé à accéder à cet élément";
      }
      else if (status === 403) {
        message = "Vous devez être connecté pour accéder à cette fonctionnalité";
        
      }
      else {
        message = errorMessage;
      }
    }
    else {
      toast.error(message);
    }

    throw new Response("Error", { status: status, statusText: message });
  }
  else if (error.request) {
    throw new Response("Error", { status: 502, statusText: errorMessage });
  }
  else {
    throw new Response("Error", { status: 502, statusText: errorMessage });
  }
}

export const callRequest = async (method, url, requestData, navigate ) => {

  var requestBody = {
    method: method,
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    timeout: 5000
  };
  if (method !== "GET") {
    requestBody["body"] = JSON.stringify(requestData);
  }

  const technicalError = "Une erreur est survenue, veuillez réessayer dans quelques instants ou contacter votre support technique";

  const response = await (fetch(process.env.REACT_APP_API_URL + url,
    requestBody
    ).catch(function (error) {
      toast.error(technicalError);
      throw Error()
    }));
    const responseData = await response.json();


    if (response.status >= 200 && response.status < 300) {
      if (responseData !== null && "message" in responseData) {
        toast.success(responseData["message"]);
      }
      return responseData;
    }
    if (responseData !== null && "message" in responseData) {
      toast.error(responseData["message"]);
    }
    else {
      toast.error(technicalError);
    }

    // non connecté
    if (response.status === 401 || response.status === 403) {
      if (navigate) {
        navigate("/logout");
      }
    }
    
    //autrement jeter une erreur

    throw Error();
}

export const callApiPost = async (url, data, navigate) => {
  const response = await callRequest("POST", url, data, navigate);
  return response;
};

export const callApiPut = async (url, data, navigate) => {
  const response = await callRequest("PUT", url, data, navigate);
  return response;

};

export const callApiGet = async (url, navigate) => {
  const response = await callRequest("GET", url, {}, navigate);
  return response;
};

export const callApiDelete = async (url, data, navigate) => {
  const response = await callRequest("DELETE", url, data, navigate);
  return response;
}

export const getCsv = async(url, data, filename) => {
  const response = await axios.post(process.env.REACT_APP_API_URL + url, data, { withCredentials: true, responseType: 'blob' }).catch(axiosError);

  // Si la réponse du serveur est réussie (code 200 par exemple), vous pouvez définir le statut de connexion sur true
  if (response.status >= 200 && response.status < 300) {
    const href = window.URL.createObjectURL(response.data);
    const anchorElement = document.createElement('a');
    anchorElement.href = href;
    anchorElement.download=filename;
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
    window.URL.revokeObjectURL(href);
  }
  else {
    throw new Response("Error", { status: response.status });
  }
}