// components/LineChart.js
import React, {useState} from "react";

function ModalYesNo({ title, before, displayNote, after, onValidate, onCancel }) {

  const [observation, setObservation] = useState("");
  const [missingInput, setMissingInput] = useState(false);
  const validate= (text) => {

    if (displayNote && (text == undefined || text.length === 0)) {
      setMissingInput(true);
        return;
    }
    onValidate(text);
  }

  return (
    <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-content">
            <header class="modal-card-head">
                <p class="modal-card-title">{title}</p>
            </header>
            <section class="modal-card-body">
                { displayNote && 
                  <div class="field">
                    <label class="required">{before}</label>
                    <input className={missingInput ? "input is-danger" : "input"} type="text" value={observation} onChange={e => setObservation(e.target.value)}/> 
                    {
                      missingInput && <p class="help is-danger">Ce champ est obligatoire</p>
                    }
                  </div>
                }
                {after}
                
            </section>
            <footer class="modal-card-foot">
                <button class="button is-danger" onClick={() => validate(observation)}>Oui</button>
                <button class="button" onClick={onCancel}>Non</button>
            </footer>
        </div>
    </div>
  );
}
export default ModalYesNo;