

export const FREQUENCE_NETTOYAGE = [
    {
        value: 1,
        label: "Quotidienne"
    },
    {
        value: 2,
        label: "Hebdomadaire"
    },
    {
        value: 6,
        label: "Bi-mensuelle"
    },
    {
        value: 3,
        label: "Mensuelle"
    },
    {
        value: 4,
        label: "Pluri-annuelle"
    },
    {
        value: 5,
        label: "Après utilisation"
    }
]

export const MOIS = [
    {
        value: 1,
        label: "Janvier"
    },
    {
        value: 2,
        label: "Février"
    },
    {
        value: 3,
        label: "Mars"
    },
    {
        value: 4,
        label: "Avril"
    },
    {
        value: 5,
        label: "Mai"
    },
    {
        value: 6,
        label: "Juin"
    },
    {
        value: 7,
        label: "Juillet"
    },
    {
        value: 8,
        label: "Août"
    },
    {
        value: 9,
        label: "Septembre"
    },
    {
        value: 10,
        label: "Octobre"
    },
    {
        value: 11,
        label: "Novembre"
    },
    {
        value: 12,
        label: "Décembre"
    },

]