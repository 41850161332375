import React, { useState, useEffect, useContext } from 'react';

import { BooleanTag, MultilineTag, CounterTag, DateTimeTag, DateTag, FilteredTag, RoleTag, AcceptationTag, MultiImageTag, LinkTag, StatutTag, ConformiteHuileTag, TemperatureTag } from '../Helper/Tag';
import { callApiGet, callApiDelete } from '../Helper/Network';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import ModalYesNo from './ModalYesNo';

import { MyContext } from '../context/MyContext';


function ArrayComponent(props) {
  const navigate = useNavigate()
  const location = useLocation().pathname.replace("/edit", "").replace("/view", "");

  const [headers, setHeaders] = useState(undefined); //headers of the column
  const [data, setData] = useState(undefined); //dataset to display
  const [orderBy, setOrderBy] = useState(undefined); //sorting column
  const [orderDirection, setOrderDirection] = useState(); //sorting order
  const [filter, setFilter] = useState(""); //search filter
  const [renderingFilter, setRenderingFilter] = useState(""); //filter used for rendering yellow over matched element
  const [actions, setActions] = useState(undefined); //available actions for item
  const [dataId, setDataId] = useState(undefined);
  const [firstLoad, setFirstLoad] = useState(true); //store first load of page
  const [pager, setPager] = useState(undefined); //pager information
  const [page, setPage] = useState(1); //current displayed page

  const [deleteLink, setDeleteLink] = useState("");
  const [modalVisible, setModalVisible] = useState(false);

  const refreshData = async () => {
    // call url with parameters for ordering and filtering
    try {
      var url = location
      url += "?";
      if (orderBy) {
        url += "&orderBy=" + orderBy;
      }
      if (orderDirection) {
        url += "&orderDirection=" + orderDirection;
      }
      if (page) {
        url += "&page=" + page;
      }
      if (filter !== undefined) {
        url += "&filter=" + filter;
        setRenderingFilter(filter);
      }
      const res = await callApiGet(url, navigate);
      //store data on reception
      res.data ? setData(res.data) : setData(undefined);
      res.headers ? setHeaders(res.headers) : setHeaders(undefined);
      res.pager ? setPager(res.pager) : setPager(undefined);
      (res.actions && res.actions.length !== 0) ? setActions(res.actions) : setActions(undefined);
      setDataId(res.id);
    }
    catch (error) {

    }
  }

  const deleteElement = (extraUrl) => {
    setDeleteLink(extraUrl);
    setModalVisible(true);
  }
  const restoreElement = async (extraUrl) => {
    var url = location;
    url += "/" + extraUrl;
    try {
      await callApiGet(url, navigate);
      refreshData();
    }
    catch (error) {

    }

  }

  const processDelete = async (note) => {
    var url = location;
    url += "/" + deleteLink;
    const data = {
      note: note
    }
    await callApiDelete(url, data);
    refreshData();
    setModalVisible(false);
  }
  const cancelDelete = () => {

    setModalVisible(false);
  }

  //only call WS when ordering or page is changed
  //avoid call to refresh while initial loading is not done
  useEffect(() => {
    if (!firstLoad) {
      refreshData();
    }
  }, [orderBy, orderDirection, page]);

  //only call WS on first load of page to avoid infinite loop
  useEffect(() => {
    if (firstLoad) {
      refreshData();
      setFirstLoad(false);
    }
  }, [data])

  //change page number
  const changePage = (newPage) => {
    setPage(newPage);
  }

  // when sorting colum change
  const selectSort = (col) => {
    setPage(1);
    if (col === orderBy) {
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      }
      else {
        setOrderDirection("ASC");
      }
    }
    else {
      setOrderBy(col);
      setOrderDirection("ASC");
    }
  }


  const handleValidate = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      refreshData();
    }
  };

  const displayNote = props.elementType !== "sonde" && props.elementType !== "utilisateur" && props.elementType !== "etablissement" && props.elementType !== "administrateur" && props.elementType !== "abonnement" 

  return (
    <>
      <div className="columns">
        <div className="column">
          <h1 className="hyappTitle">{props.title}</h1>
        </div>
        <div className="column is-half">
          {
            actions && actions.indexOf("filter") !== -1 &&
            <>
              <div className="field has-addons hyappSearch" style={{ width: "100%", justifyContent: "flex-end" }}>
                <div className="control" style={{ width: "100%" }}>
                  <input className="input" type="text" value={filter} onChange={e => setFilter(e.target.value)} onKeyDown={handleValidate}/>
                </div>
                <div className="control ">
                  <a className="button is-black is-dark" onClick={refreshData}>
                    <i className="fa fa-search" />Rechercher
                  </a>
                </div>
              </div>
            </>
          }
        </div>
      </div>
      <>
        {
          (data === undefined || data.length === 0) ?

            <>
              <div className="column is-half">
                Aucune donnée à afficher
              </div>
            </>
            :
            <table className="table is-fullwidth is-hoverable">
              <thead>
                <tr>
                  {
                    headers && headers.map((header, index) => (
                      (header.display === undefined || header.display) ? //test if data must be dsiplayed
                        (header.sort && header.sort ?
                          <th key={index} onClick={() => { selectSort(header.value); }} className={header.value === orderBy ? 'sortable sortedHeader' : 'sortable'}>
                            {header.sort && header.value === orderBy && orderDirection === "ASC" && <i className="fa fa-caret-up" />}
                            {header.sort && header.value === orderBy && orderDirection === "DESC" && <i className="fa fa-caret-down" />}
                            {header.sort && header.value !== orderBy && <i className="fa fa-sort" />}
                            &nbsp;{header.label}
                          </th>
                          : <th key={index}>{header.label}</th>)
                        :
                        <></>

                    ))
                  }
                  {
                    actions ? <th key="9999">Actions</th> : <></>
                  }
                </tr>
              </thead>
              <tbody>
                {
                  data ?
                    data.map((d, index) => (
                      <tr key={index.toString()} style={("deleted_at" in d && d["deleted_at"] !== null) ? { backgroundColor: '#FFE0DF'} : null}>
                        {
                          headers.map((header, jndex) => (
                            (header.display === undefined || header.display) ? //test if data must be displayed
                              <td key={jndex.toString()} className={header.value === orderBy ? 'sortedRow' : ''}>
                                {
                                  !header.transform && <><FilteredTag value={d[header.value]} filter={renderingFilter} /></>
                                }
                                {
                                  header.transform === "yes_no" && <BooleanTag value={d[header.value]} />
                                }
                                {
                                  header.transform === "multiline" && <MultilineTag value={d[header.value]} filter={renderingFilter} />
                                }
                                {
                                  header.transform === "counter" && <CounterTag value={d[header.value]} />
                                }
                                {
                                  header.transform === "datetime" && <DateTimeTag value={d[header.value]} filter={renderingFilter} />
                                }
                                {
                                  header.transform === "date" && <DateTag value={d[header.value]} filter={renderingFilter} />
                                }

                                {
                                  header.transform === "role" && <RoleTag value={d[header.value]} />
                                }
                                {
                                  header.transform === "acceptation" && <AcceptationTag value={d[header.value]} />
                                }
                                {
                                  header.transform === "conformitehuile" && <ConformiteHuileTag value={d[header.value]} />
                                }
                                {
                                  header.transform === "multiimage" && <MultiImageTag value={d[header.value]} path={location} />
                                }
                                {
                                  header.transform === "link" && <LinkTag value={d[header.value]} />
                                }
                                {
                                  header.transform === "temperature" && <TemperatureTag value={d[header.value]} />
                                }
                                {
                                  header.transform === "statut" && <StatutTag value={d[header.value]} />
                                }
                              </td>
                              :
                              <></>))
                        }
                        {
                          actions ? <td key="9999" style={{ fontSize: "130%" }}>
                            {
                              <>
                                {
                                  actions.indexOf("edit") !== -1 && <Link to={d[dataId] + "/edit"}><i className="fa fa-pencil-square-o" style={{ color: 'black' }} /></Link>
                                }
                                {
                                  actions.indexOf("delete") !== -1 && (("deleted_at" in d === false) || ("deleted_at" in d && d["deleted_at"] === null)) ?
                                    <Link to="#" onClick={() => { deleteElement(d[dataId] + "/delete"); }} ><i className="fa fa-trash" style={{ color: 'red' }} /></Link>
                                    : null
                                }
                                {
                                  actions.indexOf("restore") !== -1 && "deleted_at" in d && d["deleted_at"] !== null ?
                                    <Link to="#" onClick={() => { restoreElement(d[dataId] + "/restore"); }} ><i className="fa fa-recycle" style={{ color: 'green' }} /></Link>
                                    : null
                                }
                                {
                                  actions.indexOf("view") !== -1 && <Link to={d[dataId] + "/view"}><i className="fa fa-eye" style={{ color: 'black' }} /></Link>
                                }
                                {
                                  actions.indexOf("graph") !== -1 && <Link to={d[dataId] + "/graph"}><i className="fa fa-line-chart" style={{ color: 'black' }} /></Link>
                                }
                              </>
                            }
                          </td> : <></>
                        }
                      </tr>
                    ))
                    :
                    (<></>)
                }
              </tbody>
            </table>
        }
      </>
      {
        // manage pager data
        pager && (pager.hasPrevious || pager.hasNext) && (
          <div className="columns is-centered ">
            <div className="column"><span style={{ margin: "10px" }}><b>{pager.elementIndex}</b> - <b>{pager.elementIndexTo}</b> sur <b>{pager.elementIndexOn}</b></span></div>
            <div className="column is-narrow">
              {
                pager && (
                  <>
                    <a className={pager.hasPrevious ? "myPager" : "myPagerDisabled"} onClick={() => changePage(1)}><i class="fa fa-angle-double-left" />&nbsp;Premier</a>
                    <a className={pager.hasPrevious ? "myPager" : "myPagerDisabled"} onClick={() => changePage(page - 1)}><i class="fa fa-angle-left" />&nbsp;Précédent</a>
                    <a className={pager.hasNext ? "myPager" : "myPagerDisabled"} onClick={() => changePage(page + 1)}>Suivant&nbsp;<i class="fa fa-angle-right" /></a>
                    <a className={pager.hasNext ? "myPager" : "myPagerDisabled"} onClick={() => changePage(pager.pageCounter)}>Dernier&nbsp;<i class="fa fa-angle-double-right" /></a>


                  </>
                )
              }

            </div>
          </div>
        )
      }
      {
        modalVisible && <ModalYesNo 
        title={"Confirmation de suppression"} 
        before={"Veuillez indiquer une raison de suppression :"}
        displayNote={displayNote}
        after={"Voulez-vous vraiment supprimer cet élément ?"} onValidate={processDelete} onCancel={cancelDelete} />
      }

    </>
  );
}

export default ArrayComponent;
