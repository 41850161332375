import React, { useEffect, useState, useContext } from 'react';
import { MyContext } from '../context/MyContext';
import { callApiPost } from '../Helper/Network';

import hyappLogo from '../resource/img/logo-hyapp.png';
import { Form, Icon, Button } from 'react-bulma-components';

import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);
  const modeParam = queryParameters.get("mode");

  const { setLoggedUser, setConstants, setEtablissements, setSelectedEtablissement } = useContext(MyContext);

  const handleLogin = async () => {
    try {
      const response = await callApiPost("/login", {
        email: email,
        password: password
      });

      //store logged user, associated constants and etablissements if exist
      setLoggedUser(response.utilisateur);
      setConstants(response.constants);
      if (response.etablissement !== undefined) {
        setEtablissements(response.etablissement);
        if (response.etablissement.length === 1) {
          setSelectedEtablissement(response.etablissement[0]);
        }
      }

      //for superadmin and hyapp admin, display etablissement
      if (response.utilisateur.role_role_id === 0 || response.utilisateur.role_role_id === 10) {
        navigate("/etablissement");
      }
      else {
        navigate("/etablissement");
      }
    }
    catch (error) {
    }
  };

  useEffect(() => {
    //do Nothing
  }, []);


  const handleValidate = (event) => {
    if (event.key === 'Enter') {
      // 👇 Get input value
      handleLogin();
    }
  };

  return (
    <div className="container is-centered">
      <div className="columns is-centered">
        <div className="column is-12-mobile is-6-tablet is-6-desktop">
          <div style={{textAlign: 'center', marginTop: '20px'}}>
            <img src={hyappLogo} width="200px" alt="Logo Hyapp" />
          </div>
          <div style={{textAlign: 'center'}}>
          <Form.Field>
            <Form.Control>
              <Form.Input placeholder="E-mail" name="email" value={email} onChange={e => setEmail(e.target.value)} onKeyDown={handleValidate} />
              <Icon align="left">
                <i className="fa fa-user" />
              </Icon>
            </Form.Control>
          </Form.Field>
          <Form.Field>
            <Form.Control>
              <Form.Input placeholder="Mot de passe" type="password" name="password" value={password} onChange={e => setPassword(e.target.value)}  onKeyDown={handleValidate} />
              <Icon align="left">
                <i className="fa fa-lock" />
              </Icon>
            </Form.Control>
          </Form.Field>
          <Button.Group style={{justifyContent: 'center'}}>
            <Button color="black" onClick={handleLogin}>Se connecter</Button>
          </Button.Group>
          {
            modeParam === "APP" ? <span style={{color:'red'}}>Vous venez de réinitialiser votre mot de passe depuis l'application mobile, pensez à la relancer avant de vous connecter<br /><br /></span>: null
          }
          <Link to={"/forgotten"}>Mot de passe oublié ou première connexion ?</Link>
          
          <br/><br/><a href="/resources/TutoWebAppHyapp2.pdf" target="_blank">Cliquez ici pour télécharger le tutoriel pour la webapp Hyapp 2</a>
          <br/><a href="/resources/TutoMobAppHyapp2.pdf" target="_blank">Cliquez ici pour télécharger le tutoriel pour l'app Hyapp 2</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;