import React, { useEffect, useState, } from 'react';

import LineChart from '../Components/LineChart';
import { useLocation, useNavigate } from 'react-router-dom';

import { callApiGet } from '../Helper/Network';
import { HyappDatePicker } from '../Helper/Form';
import { Button } from 'react-bulma-components';

const convertDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1);
    const day = (date.getDate());
    return year.toString() + "-" + month.toString().padStart(2, '0') + "-" + day.toString().padStart(2, '0');
}

function Chart(props) {
    const navigate = useNavigate();
    const location = useLocation().pathname.replace("/edit", "").replace("/view", "").replace("/graph", "");

    const [firstLoad, setFirstLoad] = useState(true);
    const [chartData, setChartData] = useState(undefined);

    var df = new Date();
    df.setHours(0, 0, 0, 0);
    df.setMonth(df.getMonth() - 1)
    var dt = new Date();
    dt.setHours(0, 0, 0, 0);

    const [dateFrom, setDateFrom] = useState(convertDate(df));
    const [dateTo, setDateTo] = useState(convertDate(dt));

    const loadData = async () => {
        try {
            var url = location;

            //load data element
            const res = await callApiGet(url + "/graph?dateFrom=" + dateFrom + "&dateTo=" + dateTo, navigate);
            if (res) {
                setChartData(res);
            }
        }
        catch (error) {

        }
    }

    useEffect(() => {
        if (firstLoad) {
            loadData();
            setFirstLoad(false);
        }
    }, [])

    return (
        <>
            <div className="columns is-multiline is-vcentered">
                <div className="column is-one-fifth label-right">
                    <label className="label">Du :</label>
                </div>
                <div className="column is-four-fifths edit-value">
                    <HyappDatePicker value={dateFrom} callback={setDateFrom} />
                </div>
                <div className="column is-one-fifth label-right">
                    <label className="label">Au :</label>
                </div>
                <div className="column is-four-fifths edit-value">
                    <HyappDatePicker value={dateTo} callback={setDateTo} />
                </div>
            </div>
            <Button className="button is-success" onClick={loadData}>Afficher le graphique</Button>
            <div>
                {
                    chartData && <LineChart chartData={chartData} title={props.title} />
                }
                
            </div>
        </>
    );
}

export default Chart;

//chargement d'un element
export const grapheParseUrl = async ({ params }) => {
    const { element_id } = params;
    return element_id;
}



