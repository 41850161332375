import React, { useState, useContext } from 'react';
import { callApiPost } from '../Helper/Network';

import { toast } from 'react-toastify';

import { useNavigate } from 'react-router-dom';

function PasswordForgotten(props) {

    const [email, setEmail] = useState("");

    const navigate = useNavigate();

    const save = async () => {
        var passwordForgotten = {
            email: email,
        }

        try {
            await callApiPost("/require-reset", passwordForgotten);
            navigate("/login");
        }
        catch (error) {
        }
    }

    const handleValidate = (event) => {
        if (event.key === 'Enter') {
          // 👇 Get input value
          save();
        }
      };

    return (
        <>
            <div className="container is-centered">
                <div className="columns is-centered">
                    <div className="column is-12-mobile is-6-tablet is-5-desktop">

                        <h1 className="is-size-2">Mot de passe oublié</h1>
                        <div className="field is-horizontal">
                            <div className="field-label is-normal">
                                <label className="label">E-mail</label>
                            </div>
                            <div className="field-body">
                                <div className="field">
                                    <p className="control">
                                        <input className="input" placeholder="e-mail" value={email} onChange={e => setEmail(e.target.value)} onKeyDown={handleValidate} />
                                    </p>
                                </div>
                            </div>
                        </div>

                        <button className="button is-warning" onClick={save}>Demander un nouveau mot de passe</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PasswordForgotten;
