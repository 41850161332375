import React, { createContext, useState } from 'react';

export const MyContext = createContext();

export const MyProvider = ({ children }) => {
  const [loggedUser, setLoggedUser] = useState(undefined); //l'utilisateur connecté
  const [constants, setConstants] = useState(undefined); //les constantes poru l'affichage (jour, role, produit, ...)
  const [etablissements, setEtablissements] = useState(undefined); //la liste des établissements de l'utilisateur
  const [selectedEtablissement, setSelectedEtablissement] = useState(undefined); //la liste des établissements de l'utilisateur
 
  return (
    <MyContext.Provider
      value={{
        loggedUser, setLoggedUser,
        constants, setConstants,
        etablissements, setEtablissements,
        selectedEtablissement, setSelectedEtablissement,
      }}
    >
      {children}
    </MyContext.Provider>
  );
};

