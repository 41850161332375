import React, { useEffect, useContext } from 'react';

import { useNavigate } from 'react-router-dom';
import { callApiGet } from '../Helper/Network';
import { MyContext } from '../context/MyContext';

function Logout() {
  const navigate = useNavigate();

  const { setLoggedUser, setConstants, setEtablissements, setSelectedEtablissement } = useContext(MyContext);

  setLoggedUser(undefined);
  setEtablissements(undefined);
  setConstants(undefined);
  setSelectedEtablissement(undefined);

  useEffect(() => {
    navigate("/login");
    //do Nothing
  }, []);

  return (
    <></>
  );
}

export default Logout;


export const logout = async({ }) => {
  try {
    const res = await callApiGet("/logout");
    return res
  }
  catch (error) {
  }
}