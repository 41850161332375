import React, { useState, useEffect } from 'react';

import { Link, useNavigate } from 'react-router-dom';

import { callApiGet } from '../Helper/Network';

import { HyappDisplay } from '../Helper/Form'

import { useLocation } from 'react-router-dom';

function Viewer(props) {
  const location = useLocation().pathname.replace("/edit", "").replace("/view", "");

  const [firstLoad, setFirstLoad] = useState(true);
  const [data, setData] = useState(undefined);
  const [headers, setHeaders] = useState(undefined);
  const [editable, setEditable] = useState(true);

  const refreshData = async () => {
    //on appelle la page /etablissement dépendant de l'utilisateur (admin, abonne, gérant)
    try {
      const res = await callApiGet(location, navigate);
      res.data ? setData(res.data) : setData(undefined);
      res.headers  ? setHeaders(res.headers) : setHeaders(undefined);
      res.editable !== null && res.editable !== undefined ? setEditable(res.editable) : setEditable(true);
    }
    catch (error) {

    }
  }

  useEffect(() => {
    if (firstLoad) {
      refreshData();
      setFirstLoad(false);
    }
  }, [data, headers])


  const navigate = useNavigate();
	const goBack = () => {
		navigate(-1);
	}
  return (
    <>
      <div className="columns">
        <div className="column">
          <h1 className="hyappTitle">{props.title}</h1>
        </div>
      </div>
      <div className="columns is-multiline">
      {
        headers ?
          headers.map((header, index) => {
            return (<HyappDisplay label={header.label} type={header.type} value={data[header.value]} transform={header.transform} />)
          })
          : <></>
      }
      {
        props.mode && props.mode === "zone" ?
          data && data.surfaces ?
            (
              <>
              <div className="column is-one-fifth label-right">
                <label className="label">Surface et matériels associés</label>
              </div>
              <div className="column is-four-fifths display-value">
                <table className="table is-fullwidth">
                  <tr><th>Nom</th><th>Fréquence de nettoyage</th></tr>
                  {
                    data.surfaces.map((surface, index) => {
                      return (<><tr style={("deleted_at" in surface && surface["deleted_at"] !== null) ? { backgroundColor: '#FFE0DF'} : null}><td>{surface.sur_nom}</td><td>{surface.sur_libelle}</td></tr></>)
                    })
                  }
                </table>
              </div>
              </>
            )
            :<></>
          :<></>
      }
      </div>
      {
        editable && <Link className="button is-black" to={location + "/edit"}><i className="fa fa-edit" />Editer</Link>
      }
      <Link className="button is-dark" onClick={goBack}>Retour</Link>
    </>
  );
}

export default Viewer;

//chargement d'un element
export const viewerParseUrl = async ({ params }) => {
  const { element_id } = params;
  return element_id;
}



