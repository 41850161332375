import { useState } from 'react';
import Select from 'react-select';
import { AcceptationTag, BooleanTag, ConformiteHuileTag, DateTimeTag, DateTag, LinkTag, StatutTag, MultiImageTag, MultilineTag, RoleTag, TemperatureTag } from './Tag';

import { FREQUENCE_NETTOYAGE, MOIS } from '../Constants';
import { Link } from 'react-router-dom';

// element used for display only
// label = displayed label
// type = type of element
// value = the value to display
// options = options to apply to the element (transform)
export const HyappDisplay = ({ label, type, value, transform }) => {
  var workingValue = value;


  //if type is multiselect, than we have to pase string to split all elements
  /*if (workingValue && type === "multiselect") {
    workingValue = value.split("||||");
  }*/

  return (
    <>
      <div className="column is-one-fifth label-right">
        <label className="label">{label}</label>
      </div>
      <div className="column is-four-fifths display-value">
        {
          type && type === "multiselect" && workingValue ?
            transform && transform === "multiimage" ?
              <MultiImageTag value={workingValue} path={""} />
            :
            (<ul>
              {
                //on multiselect, create a list of all values
                workingValue.map((v, index) => {
                  return (
                    <li key={index} style={{ listStyleType: "square", marginLeft: "20px" }}>
                      {
                        transform ?
                          transform === "link" ?
                            <LinkTag value={v} />
                            : <>{v}</>
                          :
                          <>{v}</>
                      }
                    </li>
                  )
                })
              }
            </ul>)
          :
          <></>
        }
        {
            //manage options for displaying tags
            type && type !== "multiselect" && transform ?
              <>
                {
                  transform === "datetime" && <DateTimeTag value={workingValue} />
                }
                {
                  transform === "date" && <DateTag value={workingValue} />
                }
                {
                  transform === "link" && <LinkTag value={workingValue} />
                }
                {
                  transform === "multiline" && <MultilineTag value={workingValue} />
                }
                {
                  transform === "yes_no" && <BooleanTag value={workingValue} />
                }
                {
                  transform === "role" && <RoleTag value={workingValue} />
                }
                {
                  transform === "acceptation" && <AcceptationTag value={workingValue} />
                }
                {
                  transform === "multiimage" && <MultiImageTag value={workingValue} path={""} />
                }
                {
                  transform === "conformitehuile" && <ConformiteHuileTag value={workingValue} />
                }
                {
                  transform === "temperature" && <TemperatureTag value={workingValue} />
                }
                {
                  transform === "statut" && <StatutTag value={workingValue} />
                }
              </> :
              <></>
        }
        {
          type && type === "reception_produit" && workingValue ?
          <>
          <table className="table is-halfwidth">
          {
            workingValue.map((v,index) => {
              return (
                <>
                <tr ><th style={{borderTopWidth: 3}}>Type de produit :</th><th style={{borderTopWidth: 3}}>{v.tp_libelle}</th></tr>
                {
                  v.rec_produit_releve_temp !== null && v.type_produit !== 3 ?
                  <>
                  <tr><td>Température</td><td><TemperatureTag value={v.rec_produit_releve_temp} /></td></tr>
                  <tr><td>Température conforme</td><td><BooleanTag value={v["temperatureConforme"]} /></td></tr>
                  </>
                  : null
                }
                <tr><td>Etiquetage conforme :</td><td><BooleanTag value={v["etiquetageConforme"]} />
                {
                  v["etiquetageConforme"] === 0 ? <>&nbsp;&nbsp;{v["nc_etiquette_libelle"]}</> : null
                }
                </td></tr>
                <tr><td>Emballage conforme :</td><td><BooleanTag value={v["emballageConforme"]} />
                {
                  v["emballageConforme"] === 0 ? <>&nbsp;&nbsp;{v["nc_emballage_libelle"]}</> : null
                }
                </td></tr>
                </>
              )
            })
          }
          </table>
          </>:
          null
        }
        {
          type && type !== "multiimage" && type !== "multiselect" && type !== "reception_produit" && !transform ?
            <p>{workingValue}</p>
            :<></>
        }
      </div>
    </>);
};

// render an editable value for hyapp
// label = displayed label
// type = type of element
// value = the value to display
// callback = the function that will be called to update variable
// element = the element_name that will be modified (ie ut_nom, et_type, ...)
// reference = for select and multiselect only, to display select options
export const HyappEdit = ({ label, type, value, callback, element, reference, required, missing }) => {
  const [localValue, setLocalValue] = useState(type === "select" || type === "clearselect" || type === "multiselect" ? null : value);
  const [firstLoad, setFirstLoad] = useState(true);

  // search element for single select
  const searchSelect = (elementValue) => {
    if (typeof elementValue === "object") {  //if already an object, just return it
      return elementValue;
    }
    var foundElement = undefined;
    //if reference array is available
    if (reference) {
      reference.every((element) => {
        if (element.value === elementValue) { //find element that match the current value
          foundElement = element;
          return false; //stop process on first found
        }
        return true;
      });
    }
    return foundElement; //return element { "value": x, "label": "mon X" }
  }

  // search elements for single select
  const searchMultiSelect = (elementValue) => {
    var foundElement = [];
    if (elementValue) {
      elementValue.forEach((elt) => {
        //if element is an index (numeric) of a multiselect
        if (typeof (elt) === "number") {

          if (reference) {
            //search for reference
            reference.forEach((element) => {
              if (element.value === elt) {
                foundElement.push(element);
              }
            });
          }
        }
        else {
          foundElement.push(elt);
        }
      });
    }
    return foundElement;
  }

  const updateClear = (v) => {
    if (v) {
      update(v.value);
    }
    else {
      setLocalValue(v);
      callback(element, null);
    }
  }
  const update = (v) => {
    var newValue = v;
    var callbackValue = v;
    if (type === "select" || type === "clearselect") {
      newValue = searchSelect(v);
      callbackValue = newValue["value"];
    }
    if (type === "multiselect") {
      newValue = searchMultiSelect(v);
      callbackValue = newValue.map((elt) => { return elt.value });
      newValue.sort((a, b) => {
        return a.value < b.value ? -1 : 1;
      })
    }
    setLocalValue(newValue);

    //update parent value
    callback(element, callbackValue);
  };

  // on first load, we have to set the local value of each select and multiselect element
  // this must be done here to hanble asynchronous loading of reference array
  // firstLoad is set to false, only when reference is loaded and element is found
  // firstLoad is present to avoir reloading on next updates of the page
  if (firstLoad) {
    if ((type === "select" || type === "clearselect") && reference) {
      var eltSelect = searchSelect(value);
      if (eltSelect) {
        setLocalValue(eltSelect);
        setFirstLoad(false);
      }
    }
    if (type === "multiselect" && reference) {
      var eltMultiSelect = searchMultiSelect(value);
      if (eltMultiSelect) {
        setLocalValue(eltMultiSelect);
        setFirstLoad(false);
      }
    }
  }

  return (
    <>
      <div className="column is-one-fifth label-right">
        <label className={required ? "label required": "label"}>{label}</label>
      </div>
      <div className="column is-four-fifths edit-value">
        {
          type === "string" && <input className={missing ? "input is-danger" : "input"} placeholder={label} value={localValue} onChange={e => update(e.target.value)} />
        }
        {
          type === "select" && <Select className={missing ? "is-danger" : ""} options={reference} onChange={e => update(e.value)} value={localValue} placeholder={label} />
        }
        {
          type === "clearselect" && <Select isClearable={true} className={missing ? "is-danger" : ""} options={reference} onChange={e => updateClear(e)} value={localValue} placeholder={label} />
        }
        {
          type === "multiselect" && <Select isMulti options={reference} onChange={e => update(e)} value={localValue} placeholder={label} />
        }
        {
          type === "boolean" && 
            (localValue ? <input type="checkbox" onChange={e => update(e.target.checked)} checked/> : <input type="checkbox" onChange={e => update(e.target.checked)}/>)
        }
        {
          type === "date" && 
            <HyappDatePicker callback={e => update(e)} value={localValue} />
        }
        {
          missing && <p class="help is-danger">Ce champ est obligatoire</p>
        }
      </div>
    </>);
};


export const HyappInput = ({ label, value, options, dataElement, callback }) => {
  return (
    <>
      <div className="column is-one-fifth label-right">
        <label className="label">{label}</label>
      </div>
      <div className="column is-four-fifths display-value">
        {
          callback ?
            <input className="input" placeholder={label} value={value} onChange={e => callback(e.target.value, dataElement)} readOnly={false} />
            :
            options ?
              <>
                {
                  options.indexOf("datetime") !== -1 && <DateTimeTag value={value} />
                }
                {
                  options.indexOf("link") !== -1 && <LinkTag value={value} />
                }
              </> :
              <p>{value}</p>
        }
      </div>
    </>);
};

export const HyappSelect = (({ label, value, data, callback }) => {

  const [localValue, setLocalValue] = useState(value);

  const update = (value) => {
    setLocalValue(value);
    callback(value);
  }

  return (
    <>
      <div className="column is-one-fifth label-right">
        <label className="label">{label}</label>
      </div>

          <div className="column is-four-fifths edit-value">
            <Select options={data} onChange={(input) => update(input)} defaultValue={localValue} placeholder={label} />
        </div>
    </>)
});

export const SurfaceEdit = ({ surface, callback, deleteCallback, index }) => {
  const convertMois = (m) => {
    var data=[]
    if (m) {
      var months = m.split(",").map(function(num) { return parseInt(num, 10); });
      MOIS.forEach((month) => { 
        if (months.includes(month.value)) {
          data.push(month);
        }
      }
      )
    }
    return data;
  }

  var convertFrequence = (f) => {
    var found = {};
    if (f) {
      FREQUENCE_NETTOYAGE.every((freq) => {
        if (freq.value === f) {
          found=freq;
          return false;
        }
        return true;
      })
    }
    return found;
  }

  const [nom, setNom] = useState(surface && surface.sur_nom ? surface.sur_nom : '')
  const [id] = useState(surface && surface.sur_id ? surface.sur_id : -1)
  const [frequence, setFrequence] = useState(convertFrequence(surface && surface.sur_frequence ? surface.sur_frequence : undefined))
  const [mois, setMois] = useState(convertMois(surface && surface.sur_mois ? surface.sur_mois : undefined))
  
  const updateNom = (e) => {
    setNom(e);
    updateSurface(e, frequence, mois);
  }
  const updateFrequence = (e) => {
    setFrequence(e);
    updateSurface(nom, e, mois);
  }
  const updateMois = (e) => {
    e.sort((a, b) => {
      return a.value < b.value ? -1 : 1;
    })
    setMois(e);
    updateSurface(nom, frequence, e);
  }

  const updateSurface = (lnom, lfrequence, lmois) => {
    var surface = {
      sur_id:id,
      sur_nom:lnom,
      sur_frequence: lfrequence.value,
      sur_mois: lmois.map((m) => m.value.toString().padStart(2,'0')).join(",")
    }
    callback(index, surface);
  }


  return (
    <>
      <div className="column is-one-fifth label-right">
        <label className="label">Nom</label>
      </div>
      <div className="column is-four-fifths edit-value">
        <input className="input" placeholder={"Nom"} value={nom} onChange={e => updateNom(e.target.value)} />
      </div>
      <div className="column is-one-fifth label-right">
        <label className="label">Fréquence nettoyage</label>
      </div>
      <div className="column is-four-fifths edit-value">
        <Select options={ FREQUENCE_NETTOYAGE } onChange={e => updateFrequence(e)} value={frequence} placeholder={"Fréquence"} />
      </div>
        {
          frequence.value === 4 ?
          <>
          <div className="column is-one-fifth label-right">
            <label className="label">Mois nettoyage</label>
          </div>
          <div className="column is-four-fifths edit-value"> 
            { mois && <Select isMulti options={MOIS} onChange={e => updateMois(e)} value={mois} defaultValue={mois} placeholder={"Mois"} /> }
          </div>

          </>
          :<></>
        }
        <div className="column is-four-fifths">
        </div>
        <div className="column is-one-fifth">
        <Link className="button is-danger" onClick={(e)=> deleteCallback(index)}><i className="fa fa-trash" />Supprimer</Link>
        </div>
        
    </>
  )
};

export const ActionEdit = ({ action, callback, deleteCallback, index }) => {
  const [description, setDescription] = useState(action && action.act_description ? action.act_description : '')
  const [id] = useState(action && action.act_id ? action.act_id : -1)
  
  const updateDescription = (e) => {
    setDescription(e);
    updateAction(e);
  }

  const updateAction = (description) => {
    var action = {
      act_id:id,
      act_description:description,
    }
    callback(index,action);
  }


  return (
    <>
      <div className="column is-one-fifth label-right">
        <label className="label">Action</label>
      </div>
      <div className="column is-four-fifths edit-value">
        <input className="input" placeholder={"Action"} value={description} onChange={e => updateDescription(e.target.value)} />
      </div>
      <div className="column is-four-fifths">
        </div>
        <div className="column is-one-fifth">
        <Link className="button is-danger" onClick={(e)=> deleteCallback(index)}><i className="fa fa-trash" />Supprimer</Link>
        </div>      
    </>
  )
};


export const HyappDatePicker = ({value, callback}) => {
  const [currentDate, setCurrentDate] = useState(value);

  const updateValue = (newValue) => {
    setCurrentDate(newValue);
    callback(newValue);
  }
  return (
    <input className="input"  type="date" value={currentDate} onChange={e => updateValue(e.target.value)} />
  )

}