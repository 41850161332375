import React, { useEffect} from 'react';

import { createRoot } from 'react-dom/client';
import {
  Route,
  Routes,
  useLocation,
  HashRouter,
} from "react-router-dom";

import './index.css';
import 'bulma/css/bulma.min.css';
import "bulma-responsive-tables/bulma-responsive-tables.scss";
import 'font-awesome/css/font-awesome.min.css';


import reportWebVitals from './reportWebVitals';
import { MyProvider } from './context/MyContext';

import Login from './Screens/Login';
import Logout, { logout } from './Screens/Logout';
import Welcome from './Screens/Welcome';

import PasswordReset from './Screens/PasswordReset';
import PasswordForgotten from './Screens/PasswordForgotten';

import Lister from './Screens/Lister';
import Viewer, { viewerParseUrl } from './Screens/Viewer';
import Editor, { editorParseUrl } from './Screens/Editor';
import Chart, { grapheParseUrl } from './Screens/Chart';
import Export from './Screens/Export';

import DefaultLayout from './Screens/DefaultLayout';

import App from './App';





const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
      window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


const router = (<HashRouter>
<ScrollToTop />
  <Routes>
    <Route path="/" element={<App />} >
      <Route path="/">
        <Route index element={<Welcome />} />
        { /* specific pages elements */ }
        <Route path="password-reset" exact element={<PasswordReset />} />
        <Route path="forgotten" exact element={<PasswordForgotten />} />
        <Route path="login" exact element={<Login />} />
        <Route path="logout" exact element={<Logout />} loader={logout} />
        
        { /* First level path */ }
        <Route path="etablissement" exact element={<DefaultLayout key={"etablissement"} />}>
        
        { /* manage etablissmeent */ }
          <Route exact index element={<Lister title={"Liste des établissements"} createText={"Créer un établissement"} elementType={"etablissement"}/>} />
          <Route path=":element_id/view" exact element={<Viewer title={"Détails d'un établissement"} />} loader={viewerParseUrl} />
          <Route path=":element_id/edit" exact element={<Editor title={"Editer un établissement"} />} loader={editorParseUrl} />
        
          { /* Les établissements ont un ensemble de sous fonctionnalité */ }

          { /* les fermetures */ }
          <Route path=":element_id/fermeture_etablissement" exact element={<DefaultLayout key={"etablissement_fermeture_etablissement"} />}>
            <Route exact index element={<Lister title={"Liste des fermetures"} createText={"Créer une fermeture"}/>} loader={viewerParseUrl} elementType={"fermeture"}/>
            <Route path=":element_id/edit" exact element={<Editor title={"Créer une fermeture"}  mode={"fermeture_etablissement"}/>} loader={editorParseUrl} />
          </Route>
        
          { /* utilisateurs */ }
          <Route path=":element_id/utilisateur" exact element={<DefaultLayout key={"etablissement_utilisateur"} />}>
            <Route exact index element={<Lister title={"Liste des utilisateurs"} elementType={"utilisateur"}/>} loader={viewerParseUrl} />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails d'un utilisateur"} />} loader={viewerParseUrl} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer un utilisateur"} />} loader={editorParseUrl} />
          </Route>
        
          { /* fournisseurs */ }
          <Route path=":element_id/fournisseur" exact element={<DefaultLayout key={"etablissement_fournisseur"} />}>
            <Route exact index element={<Lister title={"Liste des fournisseurs"} createText={"Créer un fournisseur"} elementType={"fournisseur"}/>} />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails d'un fournisseur"} />} loader={viewerParseUrl} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer un fournisseur"} />} loader={editorParseUrl} />
          </Route>
        
          { /* enceintes de stockage */ }
          <Route path=":element_id/enceinte_stockage" exact element={<DefaultLayout key={"etablissement_enceinte_stockage"} />}>
            <Route exact index element={<Lister title={"Enceintes de stockage"} createText={"Créer une enceinte de stockage"} elementType={"enceinte_stockage"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails de l'enceinte de stockage"} />} loader={viewerParseUrl} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer l'enceinte de stockage"} />} loader={editorParseUrl} />
            <Route path=":element_id/graph" exact element={<Chart title={"Graphique"} />} loader={grapheParseUrl} />
          </Route>          

          { /* relevés de température de stockage */ }
          <Route path=":element_id/releve_temperature_stockage" exact element={<DefaultLayout key={"etablissement_releve_temperature_stockage"} />}>
            <Route exact index element={<Lister title={"Relevés de température"} elementType={"releve_temperature_stockage"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Température de stockage"} />} loader={viewerParseUrl} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer une température de stockage"} />} loader={editorParseUrl} />
          </Route>       

          { /* Températures de service */ }
          <Route path=":element_id/temp_service" exact element={<DefaultLayout key={"etablissement_temp_service"} />}>
            <Route exact index element={<Lister title={"T°C Sevice"} elementType={"temp_service"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails du service"} />} loader={viewerParseUrl} />
          </Route>       

          { /* cellules de mixage */ }
          <Route path=":element_id/appareil_mixage" exact element={<DefaultLayout key={"etablissement_appareil_mixage"} />}>
            <Route exact index element={<Lister title={"Appareils de mixage"} createText={"Créer un appareil de mixage"} elementType={"appareil_mixage"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails de des appareils de mixage"} />} loader={viewerParseUrl} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer l'appareil de mixage"} />} loader={editorParseUrl} />
          </Route>      

          { /* Mixe */ }
          <Route path=":element_id/mixe" exact element={<DefaultLayout key={"etablissement_mixe"} />}>
            <Route exact index element={<Lister title={"Mixés"} elementType={"mixe"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails du mixé"} />} loader={viewerParseUrl} />
          </Route>                               


          { /* Tracabilité */ }
          <Route path=":element_id/tracabilite" exact element={<DefaultLayout key={"etablissement_tracabilite"} />}>
            <Route exact index element={<Lister title={"Traçabilité"} elementType={"tracabilite"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails de l'action de traçabilité"} />} loader={viewerParseUrl} />
          </Route>                            


          { /* Cellule refroidissement */ }
          <Route path=":element_id/cellule_refroidissement" exact element={<DefaultLayout key={"etablissement_cellule_refroidissement"} />}>
            <Route exact index element={<Lister title={"Cellules de refroidissement"} createText={"Créer une cellule de refroidissement"} elementType={"cellule_refroidissement"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails de la cellule de refroidissement"} />} loader={viewerParseUrl} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer une cellule de refroidissement"} />} loader={editorParseUrl} />
          </Route>                            

          { /* Relevé de refroidissement */ }
          <Route path=":element_id/releve_refroidissement" exact element={<DefaultLayout key={"etablissement_releve_refroidissement"} />}>
            <Route exact index element={<Lister title={"Relevés de refroidissement"} elementType={"releve_refroidissement"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails du relevé de refroidissement"} />} loader={viewerParseUrl} />
          </Route>                            


          { /* Enceintes de remise en temperature */ }
          <Route path=":element_id/enceinte_remise" exact element={<DefaultLayout key={"etablissement_enceinte_remise"} />}>
            <Route exact index element={<Lister title={"Enceintes de remise en température"} createText={"Créer une enceinte"}  elementType={"enceinte_remise"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails d'une enceinte de remise en température"} />} loader={viewerParseUrl} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer une enceinte de remise en température"} />} loader={editorParseUrl} />
          </Route>                            

          { /* Relevé de remise en température */ }
          <Route path=":element_id/remise_temp" exact element={<DefaultLayout key={"etablissement_remise_temp"} />}>
            <Route exact index element={<Lister title={"Relevés de remise en température"} elementType={"remise_temp"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails de la remise en température"} />} loader={viewerParseUrl} />
          </Route>    


          { /* friteuses */ }
          <Route path=":element_id/friteuse" exact element={<DefaultLayout key={"etablissement_friteuse"} elementType={"friteuse"}/>}>
            <Route exact index element={<Lister title={"Liste des bains friteuses"} createText={"Créer un nouveau bain friteuse"} />} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer un bain friteuse"} />} loader={editorParseUrl} />
          </Route>          
        
          { /* releves d'huile */ }
          <Route path=":element_id/releve_huile" exact element={<DefaultLayout key={"etablissement_releve_huile"} />}>
            <Route exact index element={<Lister title={"Contrôle des huiles"} elementType={"releve_huile"} />} />
            <Route path=":element_id/view" exact element={<Viewer title={"Détail d'un contrôle"} />} loader={viewerParseUrl} />
          </Route>      


          { /* zones de nettoyage */ }
          <Route path=":element_id/zone" exact element={<DefaultLayout key={"etablissement_zone"} />}>
            <Route exact index element={<Lister title={"Plan de nettoyage"} createText={"Nouvelle zone"} elementType={"zone"}/>} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer une zone"} mode={"zone"} />} loader={editorParseUrl} />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails de la zone"} mode={"zone"} />} loader={viewerParseUrl} />
          </Route>          
        
          { /* nettoyage */ }
          <Route path=":element_id/nettoyage" exact element={<DefaultLayout key={"etablissement_nettoyage"} />}>
            <Route exact index element={<Lister title={"Liste des tâches de nettoyage"} elementType={"nettoyage"} />} />
          </Route>                        
        
          { /* les réceptions */ }
          <Route path=":element_id/reception" exact element={<DefaultLayout key={"etablissement_reception"} />}>
            <Route exact index element={<Lister title={"Liste des réceptions"} elementType={"reception"}/>}  />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails d'une réception"} />} loader={viewerParseUrl} />

            { /* les réceptions sont composés de produit */ }
            <Route path=":element_id/reception_produit" exact element={<DefaultLayout key={"reception_produit"} />}>
              <Route exact index element={<Lister title={"Liste des produits"} elementType={"reception_produit"}/>} />
              <Route path=":element_id/view" exact element={<Viewer title={"Détails d'un produit"} />} loader={viewerParseUrl} />
            </Route>
          </Route>

          { /* les export */ }
          <Route path=":element_id/export" exact element={<DefaultLayout key={"etablissement_export"} />}>
            <Route exact index element={<Export/>} />
          </Route>         

          { /* les non conformités */ }
          <Route path=":element_id/non_conformite" exact element={<DefaultLayout key={"etablissement_non_conformite"} />}>
            <Route exact index element={<Lister title={"Liste des non-conformités"} createText={"Créer une non conformité"} elementType={"non_conformite"} />} />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails de la non conformité"} mode={"non_conformite"}/>} loader={viewerParseUrl} />
            <Route path=":element_id/edit" exact element={<Editor title={"Editer la non conformité"} mode={"non_conformite"}/>} loader={editorParseUrl} />
          </Route>    

          { /* les notes */ }
          <Route path=":element_id/note" exact element={<DefaultLayout key={"note"} />}>
            <Route exact index element={<Lister title={"Liste des notes"} elementType={"note"}/>} />
            <Route path=":element_id/view" exact element={<Viewer title={"Détails d'une note"} />} loader={viewerParseUrl} />
          </Route>               
        </Route>
        
        { /* routes spécifiques pour l'administration */ }
        <Route path="abonnement" exact element={<DefaultLayout key={"abonnement"} />}>
          <Route exact index element={<Lister title={"Liste des abonnements"} createText={"Créer un abonnement"} elementType={"abonnement"}/>} />
          <Route path=":element_id/view" exact element={<Viewer title={"Détails d'un abonnement"} />} loader={viewerParseUrl} />
          <Route path=":element_id/edit" exact element={<Editor title={"Editer un abonnement"} />} loader={editorParseUrl} />
        </Route>
        <Route path="demo/:element_id/edit" exact element={<Editor title={"Edit un abonnement"} />} loader={editorParseUrl} />
        
        <Route path="utilisateur" exact element={<DefaultLayout key={"utilisateur"} />}>
          <Route exact index element={<Lister title={"Liste des utilisateurs"} createText={"Créer un utilisateur"} elementType={"utilisateur"}/>} />
          <Route path=":element_id/view" exact element={<Viewer title={"Détails d'un utilisateur"} />} loader={viewerParseUrl} />
          <Route path=":element_id/edit" exact element={<Editor title={"Editer un utilisateur"} />} loader={editorParseUrl} />
        </Route>
        
        <Route path="administrateur" exact element={<DefaultLayout key={"administrateur"} />}>
          <Route exact index element={<Lister title={"Liste des administrateurs"} createText={"Créer un administrateur"} elementType={"administrateur"}/>} />
          <Route path=":element_id/view" exact element={<Viewer title={"Détails d'un administrateur"} />} loader={viewerParseUrl} />
          <Route path=":element_id/edit" exact element={<Editor title={"Editer un administrateur"} />} loader={editorParseUrl} />
        </Route>
        <Route path="sonde" exact element={<DefaultLayout key={"sonde"} />}>
          <Route exact index element={<Lister title={"Liste des sondes"} elementType={"sonde"}/>} />
        </Route>



      </Route>
    </Route>
  </Routes>

  </HashRouter>)


root.render(

  <MyProvider>
      {router}
  </MyProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
