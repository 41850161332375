import { useState, useCallback } from 'react';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import ImageViewer from 'react-simple-image-viewer';

// Custom component to render boolean tag 
export const BooleanTag = ({ value }) => {
  return (
    <>
      {
        value === 1 ?
          <span className="tag is-success">
            Oui
          </span>
          :
          <span className="tag is-danger">
            Non
          </span>
      }
    </>
  );
};

// Custom component to render boolean tag 
export const RoleTag = ({ value }) => {
  if (!value) {
    return (
      <DefaultTag value={value} />
    );
  }

  var type = "tag is-danger";
  if (value === "Administrateur HyApp") {
    type = "tag is-warning";
  }
  if (value === "Gérant") {
    type = "tag is-success";
  }
  if (value === "Employé") {
    type = "tag is-link";
  }

  return (
    <span className={type}>
      {value}
    </span>
  );
};

//convert an array to a tag with size
export const CounterTag = ({ value }) => {
  if (value) {
    var length = value;
    if (typeof (value) === 'object') {
      length = value.length;
    }
    return (
      <>
        {
          <span className="tag is-black">
            {length}
          </span>
        }
      </>
    );
  }
  return (<span className="tag is-black">0</span>);

};

//covert a data to a multiline display
export const MultilineTag = ({ value, filter }) => {
  if (!value) {
    return (
      <DefaultTag value={value} />
    );
  }
  var output = "";
  if (filter) {
    const re = new RegExp(filter, "gi");
    const filtered = value.replace(re, function (str) { return "<span class=\"has-background-warning\">" + str + "</span>"; });
    output = filtered.split("\n").join("<br />");
  }
  else {
    output = value.split("\n").join("<br />");
  }
  return (
    <>
      {
        parse(output)
      }
    </>
  );
};

//when filtering array data, change style to highlight filtered data
export const FilteredTag = ({ value, filter }) => {
  if (typeof(value) != 'string') {
    return (
      <DefaultTag value={value} />
    );
  }
  if (filter && filter !== "") {
    const re = new RegExp(filter, "gi");
    const filtered = value.replace(re, function (str) { return "<span class=\"has-background-warning\">" + str + "</span>"; });
    return (
      <>
        {
          parse(filtered)
        }
      </>
    );
  }

  return (
    <DefaultTag value={value} />
  );
};

// transform a date time value to a date time tag
export const DateTimeTag = ({ value, filter }) => {
  if (value) {

    // Chaîne de caractères au format 'yyyy-mm-dd HH:MM:SS'
    var dateString = value;

    // Diviser la chaîne en parties : année, mois, jour, heures, minutes, secondes
    var parts = dateString.split(/[- :]/); // Split par "-", " ", et ":"
    var year = parseInt(parts[0]);
    var month = parseInt(parts[1]) - 1; // Les mois en JavaScript sont indexés de 0 à 11
    var day = parseInt(parts[2]);
    var hours = parseInt(parts[3]);
    var minutes = parseInt(parts[4]);
    var seconds = parseInt(parts[5]);

    var currentDate = new Date(year, month, day, hours, minutes, seconds).toLocaleString();
    if (filter) {
      const re = new RegExp(filter, "gi");
      const filtered = currentDate.replace(re, function (str) { return "<span class=\"has-background-warning\">" + str + "</span>"; });
      return parse(filtered);
    }

    return (
      <>
        {currentDate}
      </>
    )
  }
  else {
    return (
      <DefaultTag value={value} />
    );
  }
}

// transform a date time value to a date time tag
export const DateTag = ({ value, filter }) => {
  if (value) {

    // Chaîne de caractères au format 'yyyy-mm-dd'
    var dateString = value;

    // Diviser la chaîne en parties : année, mois, jour, heures, minutes, secondes
    var parts = dateString.split(/[- :]/); // Split par "-", " ", et ":"
    var year = parseInt(parts[0]);
    var month = parseInt(parts[1]) - 1; // Les mois en JavaScript sont indexés de 0 à 11
    var day = parseInt(parts[2]);

    var currentDate = new Date(year, month, day).toLocaleDateString();
    if (filter) {
      const re = new RegExp(filter, "gi");
      const filtered = currentDate.replace(re, function (str) { return "<span class=\"has-background-warning\">" + str + "</span>"; });
      return parse(filtered);
    }

    return (
      <>
        {currentDate}
      </>
    )
  }
  else {
    return (
      <DefaultTag value={value} />
    );
  }
}

export const LinkTag = ({ value }) => {
  if (value) {
    var linkParts = value.split("##");
    if (linkParts.length === 2) {
      return (
        <Link to={linkParts[0]}>{linkParts[1]}</Link>
      );
    }
    else {
      return (
        <span className="tag is-black">
          {value}
        </span>
      )
    }
  }
  return (<DefaultTag value={value} />);
}

export const DefaultTag = ({ value }) => {
  if (value) {
    return (
      <>{value}</>
    )
  } else {
    return (
      <span className="tag is-black">
        Aucun
      </span>
    )
  }
}

export const AcceptationTag = ({ value }) => {
  if (value === "Totale") {
    return (
      <span className="tag is-success">
        {value.toUpperCase()}
      </span>
    )
  }
  if (value === "Partielle") {
    return (
      <span className="tag is-warning">
        {value.toUpperCase()}
      </span>
    )
  }
  if (value === "Refus") {
    return (
      <span className="tag is-danger">
        {value.toUpperCase()}
      </span>
    )
  }
}

export const StatutTag = ({ value }) => {
  if (value === "Non traitée") {
    return (
      <span className="tag is-danger">
        {value.toUpperCase()}
      </span>
    )
  }
  if (value === "Traitement en cours") {
    return (
      <span className="tag is-warning">
        {value.toUpperCase()}
      </span>
    )
  }
  if (value === "Corrigée") {
    return (
      <span className="tag is-success">
        {value.toUpperCase()}
      </span>
    )
  }
}

export const ConformiteHuileTag = ({ value }) => {
  if (value === "Conforme") {
    return (
      <span className="tag is-success">
        {value.toUpperCase()}
      </span>
    )
  }
  if (value === "A surveiller") {
    return (
      <span className="tag is-warning">
        {value.toUpperCase()}
      </span>
    )
  }
  if (value === "Non conforme") {
    return (
      <span className="tag is-danger">
        {value.toUpperCase()}
      </span>
    )
  }
}

export const TemperatureTag = ({ value }) => {
  if (value !== null && value !== undefined) {
    return (
      <>
        {value.toFixed(2) + " °C"}
      </>)
  }
  else {
    return (
      <DefaultTag value={value} />
    );
  }
}

export const MultiImageTag = ({ value, path }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  var images = value && value.map((image) => process.env.REACT_APP_API_URL + path + "/" + image);
  if (value) {
    return (
      <>
        {
          images.map((image, index) => (
            <img
              alt={"photo " + index}
              src={image}
              onClick={() => openImageViewer(index)}
              key={index}
              className="image-preview" />
          ))
        }
        {isViewerOpen && (
          <ImageViewer
            src={images}
            currentIndex={currentImage}
            disableScroll={true}
            closeOnClickOutside={true}
            onClose={closeImageViewer}
          />
        )}
      </>
    )
  }

}